import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import PageHeader from "../components/pageHeader"
import SEO from "../components/seo"
import ScrollAnimation from 'react-animate-on-scroll';

import ImageCoaching2 from '../components/images/imageCoaching2.js'
import ImageDevelopement2 from '../components/images/imageDevelopement2.js'
import ImageTransition from '../components/images/imageTransition.js'
import ImageEmploi from '../components/images/imageEmploi.js'

const Coaching = () => (
  <Layout>
    <SEO title="Nos solutions de coaching | Gestion Mieux-Être" />
    <PageHeader
        header="Consolidez vos talents et aptitudes"
        text="Notre approche de coaching permet de créer cet espace nécessaire pour susciter une réflexion approfondie. Avec authenticité et courage, nous provoquons les occasions pour ouvrir la porte à de nouvelles perspectives."
        quote="La logique vous conduira d’un point A à un point B.  L’imagination et l’audace vous conduiront où vous le désirez
            <span>- Einstein</span>"
        background="coaching"
         />
    <div className="main-wrapper pad-page tcenter">
        <ScrollAnimation
            delay="0"
            animateIn="fadeIn">
        <h1>Nos propositions d’accompagnement</h1>
        <p class="service-presentation">Vous serez rapidement invité à actualiser votre potentiel en assurant une réelle concordance entre vos réflexions, vos talents et les avenues de solutions qui se présentent à vous.</p>

        </ScrollAnimation>
        <br /><br />
        <div className="flex-container2">
            <ScrollAnimation
                delay="10"
                animateIn="fadeIn"
                className="fourth">
            <div>
                <Link to="coaching/integration-a-de-nouvelles-fonctions">

                    <ImageCoaching2 />
                    <h6>Intégration à de nouvelles fonctions de gestion</h6>
                </Link>
            </div>
            </ScrollAnimation>
            <ScrollAnimation
                delay="20"
                animateIn="fadeIn"
                className="fourth">
            <div>
                <Link to="coaching/developpement-des-competences">
                    <ImageDevelopement2 />
                    <h6>Développement de compétences relationnelles ou de gestion</h6>
                </Link>
            </div>
            </ScrollAnimation>
            <ScrollAnimation
                delay="30"
                animateIn="fadeIn"
                className="fourth">
            <div>
                <Link to="coaching/transition-de-carriere">
                    <ImageTransition />
                    <h6>Transition de carrière</h6>
                </Link>
            </div>
            </ScrollAnimation>
            <ScrollAnimation
                delay="40"
                animateIn="fadeIn"
                className="fourth">
            <div>
                <Link to="coaching/recherche-emploi">
                    <ImageEmploi />
                    <h6>Recherche d’emploi</h6>
                </Link>
            </div>
            </ScrollAnimation>
        </div>
        <br /><br /><br />
        <ScrollAnimation
            delay="10"
            animateIn="fadeIn">
        <Link className="button-link" to="#">Nous joindre</Link>
        </ScrollAnimation>
        <br /><br />
    </div>
  </Layout>
)

export default Coaching
